<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <a-tabs v-model="activeKey">
                <!-- <a-tab-pane :key="1" tab="内容课次数据报表_365会员">
                    <Pages></Pages>
                </a-tab-pane> -->
                <a-tab-pane :key="1" tab="内容课次数据报表_主要课程">
                    <Course></Course>
                </a-tab-pane>
            </a-tabs>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
// import Pages from "./components/365page"
import Course from "./components/course"
export default {
    components: {Course},
    data() {
        return {
            spinning: false, // 全局loading
            tableLoading: false,
            activeKey:1,
        };
    },
    
    methods: {
        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
#course_category {
    padding: 10px 20px;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}
</style>
