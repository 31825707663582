// 报表相关接口
import request from '@/request/request'

// 业务数据报表
// 任务列表
export const GetStatisticsList = (params) => request.post(`/adminv2/statistics/GetStatisticsList`, params);

// 添加任务
export const AddStatistics = (params) => request.post(`/adminv2/statistics/AddStatistics`, params);

// 下载接口
export const DownStatistics = (params) => request.post(`/adminv2/statistics/DownStatistics`, params);

// 内容数据报表
// 任务列表
export const GetClassPlayCount = (params) => request.post(`/adminv2/statistics/GetClassPlayCount`, params);

// 下载接口
export const DownClassPlayCount = (params) => request.post(`/adminv2/statistics/DownClassPlayCount`, params);

// 产品播放数据报表
export const DownClassPlayLists = (params) => request.post(`/adminv2/statistics/DownClassPlayLists`, params);
export const GetClassPlayLists = (params) => request.post(`/adminv2/statistics/GetClassPlayLists`, params);

// 产品销售和整体播放数据
export const getSalesCount = (params) => request.post(`/adminv2/statistics/getSalesCount`, params);
export const downSalesCount = (params) => request.post(`/adminv2/statistics/downSalesCount`, params);

// 添加销售业绩核算订单任务
export const performanceDay = (params) => request.post(`/adminv2/statistics/performance?days=*`, params);
// 销售业绩核算订单任务列表
export const performaceList = (params) => request.post(`/adminv2/statistics/performaceList`, params);